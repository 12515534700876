var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-system" }),
        _vm._v(_vm._s(_vm.$t("Hardware Settings")) + " ")
      ]),
      _c("br"),
      _c("v-card", { attrs: { flat: "" } }, [
        _c(
          "div",
          {
            staticClass: "settingBox",
            staticStyle: { border: "none", "max-width": "100%" }
          },
          [
            _c("div", [
              _c(
                "h3",
                {
                  staticClass: "tit-group-bg mt-1",
                  staticStyle: {
                    color: "rgba(0, 0, 0, 0.6)",
                    "line-height": "1"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Default Setting")))]
              )
            ]),
            _c(
              "v-row",
              [
                _c("v-col", { staticStyle: { "max-width": "30%" } }, [
                  _c("div", { staticStyle: { "padding-left": "20px" } }, [
                    _c("p", { staticClass: "label-txt" }, [
                      _vm._v(_vm._s(_vm.$t("Max Pages")))
                    ]),
                    _c("br"),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "justify-content": "normal",
                          display: "inline-flex",
                          "padding-left": "11px"
                        }
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-select", {
                              staticClass: "form-select",
                              staticStyle: { width: "80px" },
                              attrs: {
                                items: _vm.Maxpages,
                                "item-text": "txt",
                                "item-value": "val",
                                placeholder: _vm.Maxpages[0].txt,
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                solo: "",
                                "return-object": ""
                              },
                              model: {
                                value: _vm.Maxpagesselected,
                                callback: function($$v) {
                                  _vm.Maxpagesselected = $$v
                                },
                                expression: "Maxpagesselected"
                              }
                            }),
                            _c("span", [
                              _c(
                                "p",
                                {
                                  staticClass: "label-txt",
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "margin-left": "12px",
                                    "margin-top": "10px"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("pages")) + " ")]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c("v-col", [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass: "label-txt",
                        staticStyle: { "margin-left": "-13px" }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Low Power Mode")) + " ")]
                    ),
                    _c("br"),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "justify-content": "normal",
                          display: "inline-flex"
                        }
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-select", {
                              staticClass: "form-select",
                              staticStyle: { width: "80px" },
                              attrs: {
                                items: _vm.ApprovalItems,
                                "item-text": "txt",
                                "item-value": "val",
                                placeholder: _vm.ApprovalItems[0].txt,
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                solo: "",
                                "return-object": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "0.8125rem"
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t(item.txt)))]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "LedPatternClass",
                                          staticStyle: {
                                            display: "contents !important"
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t(item.txt)))]
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.Lowpowermode,
                                callback: function($$v) {
                                  _vm.Lowpowermode = $$v
                                },
                                expression: "Lowpowermode"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn-s ml-4",
                    attrs: {
                      disabled: _vm.btnDisabledHardwareSettings,
                      text: ""
                    },
                    on: { click: _vm.Toeditdefaulfsettings }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                )
              ],
              1
            ),
            _c("br"),
            _c(
              "h3",
              {
                staticClass: "tit-group-bg mt-1",
                staticStyle: { color: "rgba(0, 0, 0, 0.6)", "line-height": "1" }
              },
              [_vm._v(_vm._s(_vm.$t("Signal Setting")))]
            ),
            _c(
              "v-row",
              [
                _c("v-col", { staticStyle: { "max-width": "30%" } }, [
                  _c("div", { staticStyle: { "padding-left": "20px" } }, [
                    _c("p", { staticClass: "label-txt" }, [
                      _vm._v(_vm._s(_vm.$t("Alive Interval for overview")))
                    ]),
                    _c("br"),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "justify-content": "normal",
                          display: "inline-flex",
                          "padding-left": "11px"
                        }
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-text-field", {
                              staticClass: "form-input",
                              staticStyle: { width: "80px" },
                              attrs: {
                                disabled: _vm.btnDisabledAliveInterval,
                                min: "0",
                                type: "number",
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                solo: ""
                              },
                              on: {
                                keyup: function($event) {
                                  $event.preventDefault()
                                  return _vm.handleKeyUp($event)
                                }
                              },
                              model: {
                                value: _vm.aliveInterval,
                                callback: function($$v) {
                                  _vm.aliveInterval = $$v
                                },
                                expression: "aliveInterval"
                              }
                            }),
                            _c("span", [
                              _c(
                                "p",
                                {
                                  staticClass: "label-txt",
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "margin-left": "12px",
                                    "margin-top": "10px"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("hours")) + " ")]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c("v-col", { staticStyle: { "max-width": "30%" } }, [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass: "label-txt",
                        staticStyle: { "margin-left": "-13px" }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("Original Scan Cycle")) + " "
                        )
                      ]
                    ),
                    _c("br"),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "justify-content": "normal",
                          display: "inline-flex"
                        }
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c("span", [
                              _c(
                                "p",
                                {
                                  staticClass: "label-txt",
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "margin-left": "0px",
                                    "margin-top": "10px"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Every")) + " ")]
                              )
                            ]),
                            _c("v-text-field", {
                              staticClass: "form-input appendIcon",
                              staticStyle: {
                                "max-width": "80px",
                                "margin-left": "10px"
                              },
                              attrs: {
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                solo: "",
                                min: "1",
                                max: "128",
                                type: "number",
                                clearable: ""
                              },
                              on: { keydown: _vm.clearTabindex },
                              model: {
                                value: _vm.origianlscancycle,
                                callback: function($$v) {
                                  _vm.origianlscancycle = $$v
                                },
                                expression: "origianlscancycle"
                              }
                            }),
                            _c("span", [
                              _c(
                                "p",
                                {
                                  staticClass: "label-txt",
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "margin-left": "10px",
                                    "margin-top": "10px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " * 10 " + _vm._s(_vm.$t("seconds")) + " "
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c("v-col", { staticStyle: { "max-width": "30%" } }, [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass: "label-txt",
                        staticStyle: { "margin-left": "-13px" }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("Rescan on Disconnection")) + " "
                        )
                      ]
                    ),
                    _c("br"),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "justify-content": "normal",
                          display: "inline-flex"
                        }
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-select", {
                              staticClass: "form-select",
                              staticStyle: { width: "180px" },
                              attrs: {
                                items: _vm.rescanondisconnect,
                                "item-text": "txt",
                                "item-value": "val",
                                placeholder: _vm.rescanondisconnect[0].txt,
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                solo: "",
                                "return-object": ""
                              },
                              model: {
                                value: _vm.rescanondisconnection,
                                callback: function($$v) {
                                  _vm.rescanondisconnection = $$v
                                },
                                expression: "rescanondisconnection"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ],
              1
            ),
            _c("br"),
            _c(
              "v-row",
              [
                _c("v-col", { staticStyle: { "max-width": "30%" } }, [
                  _c("div", { staticStyle: { "padding-left": "20px" } }, [
                    _c("p", { staticClass: "label-txt" }, [
                      _vm._v(" " + _vm._s(_vm.$t("Rescan on Bad Signal")) + " ")
                    ]),
                    _c("br"),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "justify-content": "normal",
                          display: "inline-flex",
                          "padding-left": "11px"
                        }
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-select", {
                              staticClass: "form-select",
                              staticStyle: { width: "180px" },
                              attrs: {
                                items: _vm.rescanonbadsignal,
                                "item-text": "txt",
                                "item-value": "val",
                                placeholder: _vm.rescanonbadsignal[0].txt,
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                solo: "",
                                "return-object": ""
                              },
                              model: {
                                value: _vm.rescanonbadsignalselected,
                                callback: function($$v) {
                                  _vm.rescanonbadsignalselected = $$v
                                },
                                expression: "rescanonbadsignalselected"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c("v-col", { staticStyle: { "max-width": "30%" } }, [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass: "label-txt",
                        staticStyle: { "margin-left": "-13px" }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Activation Cycle")) + " ")]
                    ),
                    _c("br"),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "justify-content": "normal",
                          display: "inline-flex"
                        }
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-select", {
                              staticClass: "form-select",
                              staticStyle: { width: "80px" },
                              attrs: {
                                items: _vm.activationcycle,
                                "item-text": "txt",
                                "item-value": "val",
                                placeholder: _vm.activationcycle[0].txt,
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                solo: "",
                                "return-object": ""
                              },
                              model: {
                                value: _vm.activationcycleselected,
                                callback: function($$v) {
                                  _vm.activationcycleselected = $$v
                                },
                                expression: "activationcycleselected"
                              }
                            }),
                            _c("span", [
                              _c(
                                "p",
                                {
                                  staticClass: "label-txt",
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "margin-left": "10px",
                                    "margin-top": "10px"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("seconds")) + " ")]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c("v-col", { staticStyle: { "max-width": "30%" } }, [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass: "label-txt",
                        staticStyle: { "margin-left": "-13px" }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("Rescan on Requiry Fail")) + " "
                        )
                      ]
                    ),
                    _c("br"),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "justify-content": "normal",
                          display: "inline-flex"
                        }
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-select", {
                              staticClass: "form-select",
                              staticStyle: { width: "80px" },
                              attrs: {
                                items: _vm.rescanonrequiryfail,
                                "item-text": "txt",
                                "item-value": "val",
                                placeholder: _vm.rescanonrequiryfail[0].txt,
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                solo: "",
                                "return-object": ""
                              },
                              model: {
                                value: _vm.rescanonrequiryfailselected,
                                callback: function($$v) {
                                  _vm.rescanonrequiryfailselected = $$v
                                },
                                expression: "rescanonrequiryfailselected"
                              }
                            }),
                            _c("span", [
                              _c(
                                "p",
                                {
                                  staticClass: "label-txt",
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "margin-left": "10px",
                                    "margin-top": "10px"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("times")) + " ")]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn-s ml-4",
                    attrs: {
                      disabled: _vm.btnDisabledHardwareSettings,
                      text: ""
                    },
                    on: { click: _vm.Toeditsignalsetting }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                )
              ],
              1
            ),
            _c("br"),
            _c(
              "h3",
              {
                staticClass: "tit-group-bg mt-1",
                staticStyle: { color: "rgba(0, 0, 0, 0.6)", "line-height": "1" }
              },
              [_vm._v(_vm._s(_vm.$t("On-Demand Alive Setting")))]
            ),
            _c("table", { staticClass: "tbl-custom-type01 max-100" }, [
              _c("colgroup", [
                _c("col", { staticStyle: { width: "25%" } }),
                _c("col", { staticStyle: { width: "25%" } }),
                _c("col", { staticStyle: { width: "25%" } }),
                _c("col", { staticStyle: { width: "25%" } }),
                _c("col")
              ]),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("On/Off")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("Cycle")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("Scheduled Time")))]),
                  _c("th")
                ])
              ]),
              _c("tbody", [
                _c("tr", [
                  _c(
                    "td",
                    [
                      _c("v-switch", {
                        staticClass: "form-switch",
                        attrs: {
                          inset: "",
                          "hide-details": "",
                          solo: "",
                          items: _vm.items,
                          "item-text": "txt",
                          "item-value": "val"
                        },
                        model: {
                          value: _vm.OnOffDemandAlive,
                          callback: function($$v) {
                            _vm.OnOffDemandAlive = $$v
                          },
                          expression: "OnOffDemandAlive"
                        }
                      })
                    ],
                    1
                  ),
                  _c("td", [_vm._v(" " + _vm._s(_vm.$t("Daily")) + " ")]),
                  _c(
                    "td",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-select", {
                            staticClass: "form-select ml-1",
                            staticStyle: { width: "0px" },
                            attrs: {
                              items: _vm.OnDamandHours,
                              dense: "",
                              "hide-details": "",
                              solo: ""
                            },
                            model: {
                              value: _vm.updateOnDemandhours,
                              callback: function($$v) {
                                _vm.updateOnDemandhours = $$v
                              },
                              expression: "updateOnDemandhours"
                            }
                          }),
                          _c("span", [
                            _c(
                              "p",
                              {
                                staticClass: "label-txt",
                                staticStyle: {
                                  "font-weight": "bold",
                                  "margin-left": "0px",
                                  "margin-top": "13px"
                                }
                              },
                              [_vm._v(" : ")]
                            )
                          ]),
                          _c("v-select", {
                            staticClass: "form-select ml-0",
                            staticStyle: { width: "0px" },
                            attrs: {
                              items: _vm.OnDamandMinutes,
                              dense: "",
                              "hide-details": "",
                              solo: ""
                            },
                            model: {
                              value: _vm.updateOnDemandminutes,
                              callback: function($$v) {
                                _vm.updateOnDemandminutes = $$v
                              },
                              expression: "updateOnDemandminutes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("td")
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn-s ml-4",
                    attrs: {
                      disabled: _vm.btnDisabledHardwareSettings,
                      text: ""
                    },
                    on: { click: _vm.ToeditOnDemandAlive }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
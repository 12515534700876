import { render, staticRenderFns } from "./HardwareSetting.vue?vue&type=template&id=4883c3fc&scoped=true&"
import script from "./HardwareSetting.vue?vue&type=script&lang=js&"
export * from "./HardwareSetting.vue?vue&type=script&lang=js&"
import style0 from "./HardwareSetting.vue?vue&type=style&index=0&id=4883c3fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4883c3fc",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCol,VRow,VSelect,VSwitch,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/-36BVGaF/0/applicationg/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4883c3fc')) {
      api.createRecord('4883c3fc', component.options)
    } else {
      api.reload('4883c3fc', component.options)
    }
    module.hot.accept("./HardwareSetting.vue?vue&type=template&id=4883c3fc&scoped=true&", function () {
      api.rerender('4883c3fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/System/HardwareSetting.vue"
export default component.exports